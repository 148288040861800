<template>
  <b-modal
    :title="`${isEdit ? 'Edit' : 'Adjust'} Salary for ${
      objAdjustSalary.user_name
    }`"
    ref="refModalAdjustSalary"
    size="md"
    @hidden="closeModal"
    @ok.prevent="updateSalary"
    no-close-on-backdrop
  >
    <ValidationObserver
      ref="form-salary"
      class="align-items-center"
      tag="b-row"
    >
      <b-col class="col-6">
        <b-form-group label="Old salary">
          <b-input-group
            :prepend="objAdjustSalary.currency === 1 ? '$' : 'S/.'"
          >
            <money
              :value="objAdjustSalary.salary"
              v-bind="{
                decimal: '.',
                thousands: ',',
                precision: 2,
              }"
              class="form-control"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <ValidationProvider
        v-slot="{ errors }"
        name="salary"
        rules="required|validate-amount"
        tag="b-col"
        class="col-6"
      >
        <b-form-group label="Enter new salary">
          <b-input-group
            :prepend="objAdjustSalary.currency === 1 ? '$' : 'S/.'"
          >
            <money
              v-model="new_salary"
              v-bind="{
                decimal: '.',
                thousands: ',',
                precision: 2,
              }"
              class="form-control"
              :class="{ 'border-danger': errors[0] && vmoneyValidate }"
            />
          </b-input-group>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        name="obs"
        rules="required"
        tag="b-col"
        class="col-12"
      >
        <b-form-group>
          <label
            class="d-flex align-items-center justify-content-between pr-1 mb-1"
          >
            <span style="font-size: 1rem"> Enter a observation: </span>
            <feather-icon
              icon="EditIcon"
              size="23"
              class="ml-1 text-primary cursor-pointer"
              @click="showModalOtherLabel = true"
            ></feather-icon>
          </label>
          {{ observation }}
          <v-select
            v-model="observation"
            label="value"
            :reduce="(option) => option.value"
            :options="salaryObservation"
            :class="{ 'border-danger': errors[0] }"
            multiple
            transition="multiple"
          >
          </v-select>
        </b-form-group>
      </ValidationProvider>
    </ValidationObserver>
    <modal-edit-observation
      v-if="showModalOtherLabel"
      @closeModal="showModalOtherLabel = false"
      @refresh="getObsSalaryRecord"
    />
  </b-modal>
</template>

<script>
import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js";
import ModalEditObservation from "@/views/management/views/salary-record/modals/ModalEditObservation.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalEditObservation,
  },
  props: {
    userObj: {
      type: Object,
      required: false,
    },
    salary_record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      new_salary: 0,
      observation: "",
      vmoneyValidate: false,
      otherLabel: "",
      showModalOtherLabel: false,
      salaryObservation: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isEdit() {
      return this.salary_record !== null;
    },
    objAdjustSalary() {
      return {
        user_name: this.isEdit
          ? this.salary_record.user_name
          : this.userObj.user_name,
        currency: this.isEdit
          ? this.salary_record.currency
          : this.userObj.currency,
        employee_id: this.isEdit
          ? this.salary_record.employee_id
          : this.userObj.employee_id,
        salary: this.isEdit
          ? this.salary_record.old_salary
          : this.userObj.salary,
        module_id: this.isEdit
          ? this.salary_record.module_id
          : this.userObj.module_id,
        user_id: this.currentUser.user_id,
      };
    },
  },
  mounted() {
    this.toggleModal("refModalAdjustSalary");
  },
  created() {
    this.new_salary = this.isEdit ? this.salary_record.new_salary : 0;
    this.observation = this.isEdit ? this.salary_record.observation : "";
    this.getObsSalaryRecord();
  },
  methods: {
    async updateSalary() {
      this.vmoneyValidate = true;
      const isValid = await this.$refs["form-salary"].validate();
      if (!isValid) return;
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;
      const params = {
        employee_id: this.objAdjustSalary.employee_id,
        old_salary: this.objAdjustSalary.salary,
        new_salary: this.new_salary,
        module_id: this.objAdjustSalary.module_id,
        observation: this.observation,
        user_id: this.currentUser.user_id,
        edit: this.isEdit,
        salary_record_id: this.isEdit ? this.salary_record.id : null,
      };
      this.addPreloader();
      try {
        await SalaryRecordService.insertOrUpdateSalary(params);
        this.showSuccessSwal();
        this.$emit("reload");
      } catch (error) {
        if (error.response.status === 400) {
          this.showErrorSwal(
            error.response.data.message,
            "Duplicate entry",
            "warning"
          );
        } else {
          this.showErrorSwal(error);
        }
      } finally {
        this.removePreloader();
      }
    },
    async getObsSalaryRecord() {
      try {
        const { data } = await SalaryRecordService.getObsSalaryRecord({
          module_id: 16,
        });
        this.salaryObservation = data.map((item) => {
          return {
            value: item.obs,
            text: item.obs,
          };
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    onChangeObservation(value) {},
  },
};
</script>
