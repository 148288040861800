<template>
  <b-modal
    ref="showModalOtherLabel"
    size="md"
    title="Maintenance Observation"
    title-tag="h2"
    ok-title="Save"
    hide-footer
    @hidden="closeModal"
  >
    <div class="d-flex flex-column">
      <div>
        <b-button
          variant="success"
          class="float-right mb-1"
          @click="createOrEditObs()"
        >
          New Observation
          <feather-icon icon="PlusIcon" size="16" />
        </b-button>
      </div>
      <b-table
        slot="table"
        ref="refOtherPayments"
        no-provider-filtering
        :items="salaryObservation"
        :fields="fields"
        striped
        small
        primary-key="id"
        table-class="text-nowrap border-radius-4"
        responsive="sm"
        show-empty
        sticky-header="200px"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(created_at)="{ item }">
          {{ item.created_at | myGlobalDay }}
        </template>

        <template #cell(actions)="{ item }">
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer mr-50 text-warning"
            size="18"
            @click="createOrEditObs(item)"
          />
          <feather-icon
            icon="TrashIcon"
            class="cursor-pointer text-danger"
            size="18"
            @click="deleteObs(item.id)"
          />
        </template>
      </b-table>
    </div>
    <b-modal
      v-model="createOrEditOtherLabel"
      size="sm"
      title="Add Observation"
      title-tag="h2"
      centered
      ok-title="Save"
      @ok.prevent="createObservation()"
    >
      <b-form-group label="Description">
        <b-form-input
          id="input-other-label"
          v-model="otherLabel"
          placeholder="Enter other label"
        />
      </b-form-group>
    </b-modal>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js";
export default {
  data() {
    return {
      createOrEditOtherLabel: false,
      otherLabel: "",
      salaryObservation: [],
      fields: [
        {
          key: "obs",
          label: "Observation",
        },
        {
          key: "user_name",
          label: "Created By",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "Created At",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
      idObs: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("showModalOtherLabel");
  },
  created() {
    this.getObsSalaryRecord();
  },
  methods: {
    async getObsSalaryRecord() {
      try {
        this.addPreloader();
        this.isBusy = true;
        const { data } = await SalaryRecordService.getObsSalaryRecord({
          module_id: 16,
        });
        this.salaryObservation = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.isBusy = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    createOrEditObs(item = null) {
      if (item) {
        this.otherLabel = item.obs;
        this.idObs = item.id;
      }
      this.createOrEditOtherLabel = true;
    },
    async createObservation() {
      try {
        await SalaryRecordService.createObservation({
          created_by: this.currentUser.user_id,
          module_id: 16,
          obs: this.otherLabel,
          id: this.idObs,
        });
        this.getObsSalaryRecord();
        this.createOrEditOtherLabel = false;
        this.otherLabel = "";
        this.$emit("refresh");
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async deleteObs(id) {
      const confirm = await this.showConfirmSwal("Are you sure to delete?");
      if (!confirm.value) return;
      try {
        await SalaryRecordService.removeObservation({ id });
        this.$emit("refresh");
        this.getObsSalaryRecord();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
