<template>
  <b-modal
    title="TRACKING SALARY RECORD"
    ref="refModalTrackingSalary"
    size="xmd"
    hide-footer
    @hidden="closeModal()"
  >
    <template>
      <b-table striped hover :items="tracking" :fields="fields">
        <template #cell(employee)>
          <span class="text-primary font-weight-bold">
            {{ salary_record.employee_name }}
          </span>
          <br />
          <span class="text-muted" v-b-tooltip.hover.top="'User Soft'">
            {{ salary_record.user_name }}
          </span>
        </template>

        <template #cell(created_by_name)="data">
          <div>
            <div>{{ data.item.created_by_name }}</div>
          </div>
        </template>

        <template #cell(observation)="data">
          <div class="d-flex flex-wrap justify-content-center" style="gap: 5px">
            <b-badge
              variant="light-info"
              v-for="item in data.item.observation"
              :key="item"
            >
              {{ item }}
            </b-badge>
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="colorStatus(data.item.status)">
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | myGlobalDay }}
        </template>
      </b-table>
    </template>
  </b-modal>
</template>
<script>
import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js";
export default {
  props: {
    salary_record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tracking: [],
      fields: [
        {
          key: "employee",
          label: "Employee",
          thStyle: { minWidth: "300px" },
        },
        {
          key: "status",
          label: "Status",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "observation",
          label: "Observation",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_by_name",
          label: "Created by",
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: { minWidth: "150px" },
        },
        {
          key: "created_at",
          label: "Created at",
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: { minWidth: "150px" },
        },
      ],
    };
  },
  mounted() {
    this.toggleModal("refModalTrackingSalary");
  },
  created() {
    this.getTracking();
  },
  methods: {
    async getTracking() {
      this.addPreloader();
      const params = { salary_record_id: this.salary_record.id };
      try {
        const response = await SalaryRecordService.getTrackingSalaryRecord(
          params
        );
        this.tracking = response.data.map((item) => {
          return {
            ...item,
            observation: this.isJson(item.observation),
          };
        });
      } catch (e) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    isJson(columna) {
      let arrayTexto;
      try {
        arrayTexto = JSON.parse(columna);
        if (!Array.isArray(arrayTexto)) {
          arrayTexto = [arrayTexto];
        }
      } catch (error) {
        arrayTexto = [columna];
      }
      return arrayTexto;
    },

    colorStatus(status) {
      const colors = {
        PENDING: "light-primary",
        OBSERVED: "light-warning",
        APPROVED: "light-success",
        REJECTED: "light-danger",
        EDITED: "light-info",
      };
      return colors[status];
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
