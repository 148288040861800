<template>
  <b-modal
    title="SALARY HISTORY"
    ref="refModalInfoSalary"
    size="xmd"
    hide-footer
    @hidden="closeModal()"
  >
    <div v-if="isCeo">
      <ValidationObserver
        ref="form-salary"
        class="align-items-center"
        tag="b-row"
      >
        <ValidationProvider
          v-slot="{ errors }"
          name="salary"
          rules="required|validate-amount"
          tag="b-col"
          class="col-5"
        >
          <b-form-group label="Enter new salary">
            <b-input-group
              :prepend="salary_record.currency === 1 ? '$' : 'S/.'"
            >
              <money
                v-model="new_salary"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  precision: 2,
                }"
                class="form-control"
                :class="{ 'border-danger': errors[0] && vmoneyValidate }"
              />
            </b-input-group>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="date"
          rules="required"
          tag="b-col"
          class="col-5"
        >
          <b-form-group label="Enter new salary">
            <b-input-group
              :prepend="salary_record.currency === 1 ? '$' : 'S/.'"
            >
              <b-datepicker
                v-model="date"
                :max="maxDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }"
                :style="{
                  'border-color': errors[0] && vmoneyValidate ? 'red' : '',
                }"
              ></b-datepicker>
            </b-input-group>
          </b-form-group>
        </ValidationProvider>
        <b-col>
          <b-button
            variant="primary"
            @click="migrateNewSalaryRecord"
            :disabled="disableMigrate"
            class="mt-1 w-100"
          >
            <feather-icon icon="ArrowRightIcon" size="16" />
            <span> Migrate </span>
          </b-button>
        </b-col>
      </ValidationObserver>
      <b-button variant="warning" @click="modalTracking = true">
        <feather-icon icon="EditIcon" size="16" class="mr-1" />
        <span>Salary History</span>
      </b-button>
      <hr />
    </div>
    <line-chart
      :p-data="chartData"
      :tooltips="tooltips"
      v-if="showChart"
      :key="reloadChart"
    />
    <template v-else>
      <div class="text-center">
        <feather-icon icon="InfoIcon" size="32" class="text-primary" />
        <h4 class="mt-1 text-primary">No Salary Record</h4>
      </div>
    </template>
    <tracking-job-details
      v-if="modalTracking"
      @close="modalTracking = false"
      :employee_id="salary_record.employee_id"
      :currency="'S/.'"
      :remove="true"
      @remove-salary="getSalariesChart"
    />
  </b-modal>
</template>

<script>
import LineChart from "@/views/commons/components/chartjs/LineChart.vue"
import moment from "moment"
import { mapGetters } from "vuex"
import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js"
// Components
import TrackingJobDetails from "@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/modals/Tracking/TrackingJobDetails"
export default {
  props: {
    salary_record: {
      type: Object,
      default: null,
    },
  },
  components: {
    LineChart,
    TrackingJobDetails,
  },
  data() {
    return {
      new_salary: 0,
      date: null,
      maxDate: new Date(),
      vmoneyValidate: false,
      reloadChart: 1,
      showChart: false,
      modalTracking: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Salary",
            data: [],
            fill: false,
            borderColor: "#2554FF",
            backgroundColor: "#2554FF",
            borderWidth: 1,
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (value, d) {
            const val = (value.yLabel / 1).toFixed(2).replace(",", ".")
            let xLabel = d.datasets[value.datasetIndex].label
            let yLabel = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            return xLabel + ": S/." + yLabel
          },
        },
      },
    }
  },
  computed: {
    isRRHH() {
      return this.$route.matched[0].meta.module === 17;
    },
    disableMigrate() {
      return !this.date || this.new_salary === 0
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },
  mounted() {
    this.toggleModal("refModalInfoSalary")
  },
  created() {
    this.getSalariesChart()
  },
  methods: {
    closeModal() {
      this.$emit("closeModal")
    },
    async getSalariesChart() {
      this.addPreloader()
      try {
        const { data } = await SalaryRecordService.getSalariesChart({
          employee_id: this.salary_record.employee_id,
        })
        this.showChart = data?.length > 0
        if (data.length === 0) return
        this.chartData.labels = data.map((salary) => {
          return moment(salary.increment_date).format("MM/DD/YYYY")
        })
        this.chartData.datasets[0].data = data.map((salary) => {
          return salary.salary
        })
        this.reloadChart++
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
    clearSalary() {
      this.date = null
      this.new_salary = 0
    },
    async migrateNewSalaryRecord() {
      const confirm = await this.showConfirmSwal()
      if (!confirm.value) return
      this.addPreloader()
      try {
        await SalaryRecordService.migrateNewSalaryRecord({
          employee_id: this.salary_record.employee_id,
          new_salary: this.new_salary,
          date: moment(this.date).format("YYYY-MM-DD"),
          module_id: this.salary_record.module_id,
          created_by: this.currentUser.user_id,
        })
        this.getSalariesChart()
        this.clearSalary()
        this.showSuccessSwal()
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
  },
}
</script>
