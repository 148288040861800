<script>
import { Line } from "vue-chartjs"
export default {
  extends: Line,
  props: {
    pData: {
      type: Object,
      default: null,
    },
    tooltips: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Line Chart",
            data: [],
            fill: false,
            borderColor: "#2554FF",
            backgroundColor: "#2554FF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  mounted() {
    this.chartData = this.pData
    if (this.tooltips) {
      this.options.tooltips = this.tooltips
    }
    this.renderChart(this.chartData, this.options)
  },
}
</script>
