var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModalAdjustSalary",attrs:{"title":((_vm.isEdit ? 'Edit' : 'Adjust') + " Salary for " + (_vm.objAdjustSalary.user_name)),"size":"md","no-close-on-backdrop":""},on:{"hidden":_vm.closeModal,"ok":function($event){$event.preventDefault();return _vm.updateSalary.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"form-salary",staticClass:"align-items-center",attrs:{"tag":"b-row"}},[_c('b-col',{staticClass:"col-6"},[_c('b-form-group',{attrs:{"label":"Old salary"}},[_c('b-input-group',{attrs:{"prepend":_vm.objAdjustSalary.currency === 1 ? '$' : 'S/.'}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"value":_vm.objAdjustSalary.salary,"disabled":""}},'money',{
              decimal: '.',
              thousands: ',',
              precision: 2,
            },false))],1)],1)],1),_c('ValidationProvider',{staticClass:"col-6",attrs:{"name":"salary","rules":"required|validate-amount","tag":"b-col"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Enter new salary"}},[_c('b-input-group',{attrs:{"prepend":_vm.objAdjustSalary.currency === 1 ? '$' : 'S/.'}},[_c('money',_vm._b({staticClass:"form-control",class:{ 'border-danger': errors[0] && _vm.vmoneyValidate },model:{value:(_vm.new_salary),callback:function ($$v) {_vm.new_salary=$$v},expression:"new_salary"}},'money',{
              decimal: '.',
              thousands: ',',
              precision: 2,
            },false))],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-12",attrs:{"name":"obs","rules":"required","tag":"b-col"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"d-flex align-items-center justify-content-between pr-1 mb-1"},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v(" Enter a observation: ")]),_c('feather-icon',{staticClass:"ml-1 text-primary cursor-pointer",attrs:{"icon":"EditIcon","size":"23"},on:{"click":function($event){_vm.showModalOtherLabel = true}}})],1),_vm._v(" "+_vm._s(_vm.observation)+" "),_c('v-select',{class:{ 'border-danger': errors[0] },attrs:{"label":"value","reduce":function (option) { return option.value; },"options":_vm.salaryObservation,"multiple":"","transition":"multiple"},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1)]}}])})],1),(_vm.showModalOtherLabel)?_c('modal-edit-observation',{on:{"closeModal":function($event){_vm.showModalOtherLabel = false},"refresh":_vm.getObsSalaryRecord}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }