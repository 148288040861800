<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        v-if="!isCompleted && (isManagement)"
        variant="success"
        class="my-1 mx-1 d-flex align-items-center"
        style="gap: 0.5rem"
        @click="showModalSalary = true"
      >
        <feather-icon
          icon="TrendingUpIcon"
          size="16"
        />
        NEW SALARY RECORD
      </b-button>
    </div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="resetSearch"
    >
      <template #table>
        <b-table
          ref="refSalaryRecord"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          small
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(employee)="data">
            <router-link
              target="_blank"
              :class="isDarkSkin ? 'text-warning' : 'text-primary'"
              :to="{
                name: employeeRoute,
                params: { id: data.item.employee_id },
              }"
            >
              {{ data.item.employee_name }}
            </router-link>
            <br>
            <span
              v-b-tooltip.hover.top="'User Soft'"
              class="text-muted"
            >
              {{ data.item.user_name }}
            </span>
          </template>

          <template #cell(old_salary)="data">
            {{ `${data.item.currency === 1 ? "$" : "S/."}` }}
            {{ data.item.old_salary | currency }}
          </template>

          <template #cell(new_salary)="data">
            <span
              :class="colorSalary(data.item)"
              class="d-flex align-items-center"
            >
              <feather-icon
                :icon="iconSalary(data.item)"
                size="16"
              />
              <span>
                {{ `${data.item.currency === 1 ? "$" : "S/."}` }}
                {{ data.item.new_salary | currency }}
              </span>
            </span>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="colorStatus(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_by_name }}
            <br>
            {{ data.item.created_at | myGlobalDay }}
          </template>

          <template #cell(actions)="data">
            <tabler-icon
              icon="FileTextIcon"
              size="16"
              class="text-info clickable"
              @click="openModalShowInfo(data.item)"
            />
            <feather-icon
              icon="ListIcon"
              size="16"
              class="text-primary clickable ml-1"
              @click="openModalTrackingSalary(data.item)"
            />
            <feather-icon
              v-if="!isRRHH && data.item.status === 'OBSERVED'"
              v-b-tooltip.hover.bottom="'Send to RRHH'"
              icon="CornerUpRightIcon"
              size="16"
              class="text-success clickable ml-1"
              @click="editSalary(data.item, 3)"
            />
            <feather-icon
              v-if="isRRHH && ['PENDING', 'EDITED'].includes(data.item.status)"
              v-b-tooltip.hover.bottom="'Return to CEO'"
              icon="CornerUpLeftIcon"
              size="16"
              class="text-danger clickable ml-1"
              @click="openObservationSalary(data.item, 3)"
            />
            <feather-icon
              v-if="isRRHH && ['PENDING', 'EDITED'].includes(data.item.status)"
              icon="CheckCircleIcon"
              size="16"
              class="text-success clickable ml-1"
              @click="acceptSalary(data.item)"
            />
            <feather-icon
              v-if="
                !isRRHH && !['APPROVED', 'REJECTED'].includes(data.item.status)
              "
              icon="XCircleIcon"
              size="16"
              class="text-danger clickable ml-1"
              @click="rejectSalary(data.item)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-show-info
      v-if="showModalShowInfo"
      :salary_record="salaryRecord"
      @closeModal="showModalShowInfo = false"
    />

    <modal-new-salary
      v-if="showModalSalary"
      @closeModal="showModalSalary = false"
      @refreshPendings="resetSearch()"
    />
    <modal-tracking-salary
      v-if="showModalTrackingSalary"
      :salary_record="salaryRecord"
      @closeModal="showModalTrackingSalary = false"
    />
    <modal-observation
      v-if="showModalObservation"
      :salary_record="salaryRecord"
      @closeModal="
        showModalObservation = false;
        resetSearch();
      "
    />
    <modal-adjust-salary
      v-if="showModalAdjustSalary"
      :salary_record="salaryRecord"
      @closeModal="showModalAdjustSalary = false"
      @reload="
        resetSearch();
        showModalAdjustSalary = false;
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import filterData from '@/views/management/views/salary-record/filters/salary-grid.filter.js';
import SalaryRecordService from '@/views/management/views/salary-record/salary-record.service.js';
import helpdeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';
// Components
import ModalNewSalary from '@/views/management/views/salary-record/modals/ModalNewSalary.vue';
import ModalTrackingSalary from '@/views/management/views/salary-record/modals/ModalTrackingSalary.vue';
import ModalObservation from '@/views/management/views/salary-record/modals/ModalObservation.vue';
import ModalAdjustSalary from '@/views/management/views/salary-record/modals/ModalAdjustSalary.vue';
import ModalShowInfo from '@/views/management/views/salary-record/modals/ModalShowInfo.vue';

export default {
  components: {
    ModalNewSalary,
    ModalTrackingSalary,
    ModalObservation,
    ModalAdjustSalary,
    ModalShowInfo,
  },
  data() {
    return {
      filters: filterData,
      sourcesname_id: '',
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by employee name or user name',
        model: '',
      },
      fields: [
        {
          key: 'employee',
          label: 'Employee',
          visible: true,
        },
        {
          key: 'module_name',
          label: 'Module',
          visible: true,
        },
        {
          key: 'old_salary',
          label: 'Old Salary',
          visible: true,
        },
        {
          key: 'new_salary',
          label: 'New Salary',
          visible: true,
        },
        {
          key: 'status',
          label: 'Status',
          visible: true,
        },
        {
          key: 'created_at',
          label: 'Created By',
          thClass: 'text-center',
          tdClass: 'text-center',
          visible: true,
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          tdClass: 'text-center',
          visible: true,
        },
      ],
      totalRows: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      showModalSalary: false,
      salaryRecord: null,
      showModalTrackingSalary: false,
      showModalObservation: false,
      showModalAdjustSalary: false,
      showModalShowInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
    isCompleted() {
      return this.$route.meta.completed || false;
    },
    isManagement() {
      return this.$route.matched[0].meta.module === 16;
    },
    isRRHH() {
      return this.$route.matched[0].meta.module === 17;
    },
    employeeRoute() {
      return this.$route.matched[1].meta?.employeeRoute;
    },
  },
  created() {
    this.getModules();
    this.fillArrayStatus();
  },
  methods: {
    async myProvider(ctx) {
      const params = {
        user_name: this.filterPrincipal.model,
        page: ctx.currentPage,
        per_page: ctx.perPage,
        completed: this.isCompleted,
        from: this.filters[0].model,
        to: this.filters[1].model,
        module_id: this.filters[2].model,
        status: this.filters[3].model,
      };
      this.addPreloader();
      try {
        const { data } = await SalaryRecordService.getSalaryRecords(params);
        const items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return items || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async resetSearch() {
      this.$refs.refSalaryRecord.refresh();
      await this.$store.dispatch('SalaryRecordStore/A_GET_COUNTER_SALARY');
    },

    async getModules() {
      const { data } = await helpdeskService.getModules();
      this.modules = data.map(item => {
        item.text = item.name;
        item.value = item.id;
        return item;
      });
      this.filters[2].options = this.modules;
    },

    fillArrayStatus() {
      let status = [
        { text: 'PENDING', value: 1 },
        { text: 'EDITED', value: 5 },
        { text: 'OBSERVED', value: 3 },
        { text: 'APPROVED', value: 2 },
        { text: 'REJECTED', value: 4 },
      ];
      if (this.isCompleted) {
        status = status.filter(item => [2, 4].includes(item.value));
      } else {
        status = status.filter(item => [1, 3, 5].includes(item.value));
      }
      this.filters[3].options = status;
    },

    colorStatus(status) {
      const colors = {
        PENDING: 'light-primary',
        OBSERVED: 'light-warning',
        APPROVED: 'light-success',
        REJECTED: 'light-danger',
        EDITED: 'light-info',
      };
      return colors[status];
    },

    colorSalary(salary) {
      const increase = salary.new_salary > salary.old_salary;
      return increase ? 'text-success' : 'text-danger';
    },

    iconSalary(salary) {
      const increase = salary.new_salary > salary.old_salary;
      return increase ? 'ArrowUpIcon' : 'ArrowDownIcon';
    },

    async openModalTrackingSalary(salaryRecord) {
      this.salaryRecord = salaryRecord;
      this.showModalTrackingSalary = true;
    },

    async openObservationSalary(salaryRecord, status) {
      this.salaryRecord = {
        ...salaryRecord,
        status,
      };
      this.showModalObservation = true;
    },

    async acceptSalary(salaryRecord) {
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;
      this.addPreloader();
      try {
        await SalaryRecordService.acceptSalary({
          status: salaryRecord.status,
          salary_record_id: salaryRecord.id,
          created_by: this.currentUser.user_id,
          new_salary: salaryRecord.new_salary,
          old_salary: salaryRecord.old_salary,
          employee_id: salaryRecord.employee_id,
        });
        this.resetSearch();
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
    async rejectSalary(salaryRecord) {
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;
      this.addPreloader();
      try {
        await SalaryRecordService.rejectSalary({
          salary_record_id: salaryRecord.id,
          created_by: this.currentUser.user_id,
        });
        this.resetSearch();
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    editSalary(salaryRecord) {
      this.salaryRecord = salaryRecord;
      this.showModalAdjustSalary = true;
    },

    openModalShowInfo(salaryRecord) {
      this.salaryRecord = salaryRecord;
      this.showModalShowInfo = true;
    },
  },
};
</script>
