var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"refModalInfoSalary",attrs:{"title":"SALARY HISTORY","size":"xmd","hide-footer":""},on:{"hidden":function($event){return _vm.closeModal()}}},[(_vm.isCeo)?_c('div',[_c('ValidationObserver',{ref:"form-salary",staticClass:"align-items-center",attrs:{"tag":"b-row"}},[_c('ValidationProvider',{staticClass:"col-5",attrs:{"name":"salary","rules":"required|validate-amount","tag":"b-col"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Enter new salary"}},[_c('b-input-group',{attrs:{"prepend":_vm.salary_record.currency === 1 ? '$' : 'S/.'}},[_c('money',_vm._b({staticClass:"form-control",class:{ 'border-danger': errors[0] && _vm.vmoneyValidate },model:{value:(_vm.new_salary),callback:function ($$v) {_vm.new_salary=$$v},expression:"new_salary"}},'money',{
                decimal: '.',
                thousands: ',',
                precision: 2,
              },false))],1)],1)]}}],null,false,1455488755)}),_c('ValidationProvider',{staticClass:"col-5",attrs:{"name":"date","rules":"required","tag":"b-col"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Enter new salary"}},[_c('b-input-group',{attrs:{"prepend":_vm.salary_record.currency === 1 ? '$' : 'S/.'}},[_c('b-datepicker',{style:({
                'border-color': errors[0] && _vm.vmoneyValidate ? 'red' : '',
              }),attrs:{"max":_vm.maxDate,"date-format-options":{
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)]}}],null,false,3625385254)}),_c('b-col',[_c('b-button',{staticClass:"mt-1 w-100",attrs:{"variant":"primary","disabled":_vm.disableMigrate},on:{"click":_vm.migrateNewSalaryRecord}},[_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"16"}}),_c('span',[_vm._v(" Migrate ")])],1)],1)],1),_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){_vm.modalTracking = true}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"EditIcon","size":"16"}}),_c('span',[_vm._v("Salary History")])],1),_c('hr')],1):_vm._e(),(_vm.showChart)?_c('line-chart',{key:_vm.reloadChart,attrs:{"p-data":_vm.chartData,"tooltips":_vm.tooltips}}):[_c('div',{staticClass:"text-center"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"InfoIcon","size":"32"}}),_c('h4',{staticClass:"mt-1 text-primary"},[_vm._v("No Salary Record")])],1)],(_vm.modalTracking)?_c('tracking-job-details',{attrs:{"employee_id":_vm.salary_record.employee_id,"currency":'S/.',"remove":true},on:{"close":function($event){_vm.modalTracking = false},"remove-salary":_vm.getSalariesChart}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }