<template>
  <b-modal
    title="OBSERVATION"
    ref="refModalObservation"
    size="md"
    @ok.prevent="sendObservation"
    @hidden="closeModal()"
  >
    <ValidationObserver ref="form-obs" class="align-items-center" tag="b-row">
      <ValidationProvider
        v-slot="{ errors }"
        name="obs"
        rules="required"
        tag="b-col"
        class="col-12"
      >
        <b-form-group>
          <label
            class="d-flex align-items-center justify-content-between pr-1 mb-1"
          >
            <span style="font-size: 1rem"> Enter a observation: </span>
            <feather-icon
              icon="PlusCircleIcon"
              size="25"
              class="ml-1 text-success cursor-pointer"
              @click="showModalOtherLabel = true"
            ></feather-icon>
          </label>
          <b-form-select
            v-model="observation"
            :class="{ 'border-danger': errors[0] }"
            :options="salaryObservation"
            @change="onChangeObservation"
          ></b-form-select>
        </b-form-group>
      </ValidationProvider>
    </ValidationObserver>
    <b-modal
      v-model="showModalOtherLabel"
      size="sm"
      title="Add Observation"
      title-tag="h2"
      centered
      ok-title="Save"
      @ok.prevent="createObservation()"
    >
      <b-form-group label="Description">
        <b-form-input
          id="input-other-label"
          v-model="otherLabel"
          placeholder="Enter other label"
        />
      </b-form-group>
    </b-modal>
  </b-modal>
</template>
<script>
import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    salary_record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      observation: "",
      showModalOtherLabel: false,
      otherLabel: "",
      salaryObservation: [],
    };
  },
  mounted() {
    this.toggleModal("refModalObservation");
  },
  created() {
    this.getObsSalaryRecord();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async sendObservation() {
      const isValid = await this.$refs["form-obs"].validate();
      if (!isValid) return;
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;
      this.addPreloader();
      try {
        await SalaryRecordService.sendObservation({
          status: this.salary_record.status,
          salary_record_id: this.salary_record.id,
          observation: this.observation,
          created_by: this.currentUser.user_id,
        });
        this.closeModal();
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async getObsSalaryRecord() {
      try {
        const { data } = await SalaryRecordService.getObsSalaryRecord({
          module_id: 17,
        });
        this.salaryObservation = data.map((item) => {
          return {
            value: item.obs,
            text: item.obs,
          };
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async createObservation() {
      try {
        const { data } = await SalaryRecordService.createObservation({
          created_by: this.currentUser.user_id,
          module_id: 17,
          obs: this.otherLabel,
        });
        this.salaryObservation = data.map((item) => {
          return {
            value: item.obs,
            text: item.obs,
          };
        });
        this.showModalOtherLabel = false;
        this.otherLabel = "";
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    onChangeObservation(value) {},
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
