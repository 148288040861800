<template>
  <b-modal
    ref="refModalNewSalary"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="New Salary Record"
    hide-footer
    size="xlg"
    @hidden="closeModal"
  >
    <div>
      <ValidationObserver ref="form" tag="b-row" class="align-items-center">
        <ValidationProvider
          v-slot="{ errors }"
          name="subject"
          rules="required"
          tag="b-col"
          class="col-6"
        >
          <b-form-group
            description="Enter name of employee."
            label="Enter name of employee"
            label-for="input-1"
            :state="errors.length > 0 ? false : state"
          >
            <b-form-input
              id="input-1"
              v-model="user_name"
              trim
              @keyup.enter="searchEmployee"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="subject"
          rules="required"
          tag="b-col"
          class="col-4"
        >
          <b-form-group
            description="Select module."
            label="Module"
            label-for="input-2"
            :state="errors.length > 0 ? false : state"
          >
            <b-select v-model="module" :options="modules"></b-select>
          </b-form-group>
        </ValidationProvider>

        <b-col>
          <b-button
            type="submit"
            variant="primary"
            @click="searchEmployee"
            class="mb-1 w-100"
          >
            Search
            <feather-icon icon="SearchIcon" size="16" />
          </b-button>
        </b-col>
      </ValidationObserver>
      <hr />
      <b-table
        id="cards-list"
        no-border-collapse
        class="position-relative mt-1"
        :fields="fields"
        show-empty
        no-provider-filtering
        sticky-header="45vh"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        :items="items"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(employee)="data">
          <div class="d-flex">
            <div class="mr-1">
              <feather-icon
                v-if="data.item.active == 1"
                icon="CheckCircleIcon"
                class="text-success"
                size="14"
                v-b-tooltip.hover.top="'Active'"
              />
              <feather-icon
                v-else
                icon="XCircleIcon"
                class="text-danger"
                size="14"
                v-b-tooltip.hover.top="'Inactive'"
              />
            </div>
            <div>
              <span
                style="font-weight: 600"
                :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                v-if="data.item.employee_name"
              >
                {{ data.item.employee_name }}
              </span>
              <span class="text-warning" v-else> No user related </span>
              <br />
              <span class="text-muted" v-b-tooltip.hover.top="'User Soft'">
                {{ data.item.user_name }}
              </span>
            </div>
          </div>
        </template>

        <template #cell(salary)="data">
          <div v-if="data.item.salary">
            {{
              `${
                data.item.currency === 1 ? "$" : "S/."
              } ${data.item.salary.toFixed(2)}`
            }}
          </div>
          <span class="text-muted" v-else>Not set</span>
        </template>

        <template #cell(role_name)="data">
          <div v-if="data.item.role_name">
            {{ data.item.role_name }}
          </div>
          <span class="text-muted" v-else>Not set</span>
        </template>

        <template #cell(increase_date)="data">
          <div
            v-if="data.item.increase_date"
            :class="colorForDate(data.item.increase_date)"
          >
            {{ data.item.increase_date | myGlobal }}
            <br />
            {{ lastIncreaseDateToMonth(data.item.increase_date) }}
          </div>
          <span class="text-muted" v-else>Not set</span>
        </template>

        <template #cell(start_date)="data">
          <div v-if="data.item.start_date">
            {{ data.item.start_date | myGlobal }}
          </div>
          <span class="text-muted" v-else>Not set</span>
        </template>

        <template #cell(action)="data">
          <div
            v-if="data.item.employee_id"
            class="d-flex align-items-center"
            style="gap: 0.5rem"
          >
            <tabler-icon
              icon="FileTextIcon"
              size="23"
              class="text-info clickable"
              v-b-tooltip.hover.top="'Salary Report'"
              @click="openModalShowInfo(data.item)"
            />
            <tabler-icon
              v-if="data.item.active == 1"
              icon="CurrencyDollarIcon"
              size="23"
              class="text-success clickable"
              v-b-tooltip.hover.top="'Adjust Salary'"
              @click="openModalAdjustSalary(data.item)"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
      />

      <modal-show-info
        v-if="showModalShowInfo"
        :salary_record="userObj"
        @closeModal="
          showModalShowInfo = false;
          reloadTable();
        "
      />

      <modal-adjust-salary
        :userObj="userObj"
        v-if="showModalAdjustSalary"
        @closeModal="closeAdjustSalary"
        @reload="
          reloadTable();
          refreshPendings();
          showModalAdjustSalary = false;
        "
      />
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
// Modal
import ModalAdjustSalary from "@/views/management/views/salary-record/modals/ModalAdjustSalary.vue";
import ModalShowInfo from "@/views/management/views/salary-record/modals/ModalShowInfo.vue";
export default {
  components: {
    ModalAdjustSalary,
    ModalShowInfo,
  },
  data() {
    return {
      user_name: "",
      module: null,
      modules: [],
      fields: [
        {
          key: "employee",
          label: "Employee",
        },
        {
          key: "module_name",
          label: "Module",
        },
        {
          key: "role_name",
          label: "Position",
        },
        {
          key: "salary",
          label: "Salary",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "increase_date",
          label: "Last Increase",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "action",
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      items: [],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      showModalAdjustSalary: false,
      showModalShowInfo: false,
      userObj: {},
    };
  },
  computed: {
    isRRHH() {
      return this.$route.matched[0].meta.module === 17;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    state() {
      return this.user_name.length >= 4;
    },
    invalidFeedback() {
      return this.user_name.length < 4
        ? "Name must be at least 4 characters"
        : "";
    },
  },
  mounted() {
    this.toggleModal("refModalNewSalary");
  },
  created() {
    this.getModules();
  },
  methods: {
    async getModules() {
      const { data } = await helpdeskService.getModules();
      this.modules = data.map((item) => {
        item.text = item.name;
        item.value = item.id;
        return item;
      });
    },

    async searchEmployee() {
      this.isBusy = true;
      this.reloadTable();
      this.isBusy = false;
    },

    async reloadTable() {
      const params = {
        user_name: this.user_name,
        module: this.module,
        page: this.currentPage,
        per_page: this.perPage,
      };
      this.addPreloader();
      try {
        const { data } = await SalaryRecordService.getEmployees(params);
        this.items = data.data;
        this.totalRows = data.total;
        this.currentPage = data.current_page;
        this.perPage = data.per_page;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async openModalAdjustSalary(item) {
      this.userObj = item;
      this.showModalAdjustSalary = true;
    },

    closeModal() {
      this.$emit("closeModal");
    },

    closeAdjustSalary() {
      this.showModalAdjustSalary = false;
    },

    colorForDate(date) {
      const today = moment();
      const dateToCompare = moment(date);
      const month = today.diff(dateToCompare, "months");
      if (month > 6) return "text-danger";
      if (month > 3) return "text-warning";
      return "text-primary";
    },

    lastIncreaseDateToMonth(date) {
      const today = moment();
      const dateToCompare = moment(date);
      const month = today.diff(dateToCompare, "months");
      return month <= 0 ? "( this month )" : `( ${month} month ago)`;
    },

    openModalShowInfo(item) {
      this.userObj = item;
      this.showModalShowInfo = true;
    },

    refreshPendings() {
      this.$emit("refreshPendings");
    },
  },
  watch: {
    currentPage() {
      this.searchEmployee();
    },
  },
};
</script>
